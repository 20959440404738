import { createSlice } from '@reduxjs/toolkit'

export const getTotalAmount = (bomItem) => {
  const amount = +bomItem.unitRate * +bomItem.quantity
  const gstAmount = amount * (+bomItem.gstRate / 100)
  const totalAmount = amount + gstAmount
  return {totalAmount, gstAmount}
}

const EMPTY_PURCHASE_BILL = {
  files:[],
  invoiceFile:null,
  subtotal: 0,
  gstTotal: 0,
  isUploaded: false,
  totalOtherCharges: 0,
  gstOnOtherCharges: 0,
  grandTotal: 0,
  otherCharges: {
    transport: {
      price: 0,
      gstPercentage: 0
    },
    handling: {
      price: 0,
      gstPercentage: 0
    },
    otherCharges: {
      price: 0,
      gstPercentage: 0
    }
  },

}

export const PURCHASE_BILL_MODAL_MODE = {
  EDIT: 'EDIT',
  VIEW: 'VIEW',
  CREATE: 'CREATE'
}

const purchaseBillsSlice = createSlice({
  name: 'purchaseBills',
  initialState: {
    purchaseBillModalMode: PURCHASE_BILL_MODAL_MODE.CREATE,
    activePurchaseBill: EMPTY_PURCHASE_BILL
  },
  reducers: {
    setPurchaseBillModalMode(state, action) {
      state.purchaseBillModalMode = action.payload
    },
    setActivePurchaseBill(state, action) {
      state.activePurchaseBill = { ...state.activePurchaseBill, ...action.payload }
    },
    resetActivePurchaseBill(state) {
      state.activePurchaseBill = EMPTY_PURCHASE_BILL
    },

    addBomItem(state, action) {
      const totalAmounts = getTotalAmount(action.payload)
      state.activePurchaseBill.boms = state.activePurchaseBill.boms.concat({
        ...action.payload,
        ...totalAmounts
      })
    },
    addMaterialItem(state, action) {
      state.activePurchaseBill.materials = state.activePurchaseBill.materials.concat(action.payload)
    },
    setMaterials(state, action) {
      state.activePurchaseBill.materials = action.payload
    },
    removeMaterialItem(state, action) {
      state.activePurchaseBill.materials = state.activePurchaseBill.materials.filter(
        (item) => item.itemId !== action.payload
      )
    },
  
    setBuyerComment(state, action) {
      state.activePurchaseBill.buyerComment = action.payload
    },
    setTransportChargesInBill: (state, action) => {
      state.activePurchaseBill.otherCharges.transport.price = action.payload.price
      state.activePurchaseBill.otherCharges.transport.gstPercentage = action.payload.gstPercentage
    },
    setHandlingChargesInBill: (state, action) => {
      state.activePurchaseBill.otherCharges.handling.price = action.payload.price
      state.activePurchaseBill.otherCharges.handling.gstPercentage = action.payload.gstPercentage
    },
    setAdditionalChargesInBill: (state, action) => {
      state.activePurchaseBill.otherCharges.otherCharges.price = action.payload.price
      state.activePurchaseBill.otherCharges.otherCharges.gstPercentage = action.payload.gstPercentage
    },
    setFiles: (state, action) => {
      state.activePurchaseBill.files = action.payload
    },
    setInvoiceFile : (state, action) =>{
       state.activePurchaseBill.invoiceFile = action.payload
    },
    setSubtotal: (state, action) => {
      state.activePurchaseBill.subtotal = action.payload
    },
    setGstTotal: (state, action) => {
      state.activePurchaseBill.gstTotal = action.payload
    },
    setTotalOtherCharges: (state, action) => {
      state.activePurchaseBill.totalOtherCharges = action.payload
    },
    setGstOnOtherCharges: (state, action) => {
      state.activePurchaseBill.gstOnOtherCharges = action.payload
    },
    setGrandTotal: (state, action) => {
      state.activePurchaseBill.grandTotal = action.payload
    },
    setPurchaseBillUploaded: (state, action) => {
      state.activePurchaseBill.isUploaded = action.payload
    },
    setIsGstIncludedInPrice: (state, action) => {
      state.activePurchaseBill.isGstIncludedInPrice = action.payload
    },

    resetPurchaseBill: (state, action) => {
      state.activePurchaseBill = { ...EMPTY_PURCHASE_BILL }
    }
  }
})

export const {
  setActivePurchaseBill,
  setMaterials,
  removeMaterialItem,
  resetActivePurchaseBill,
  editMaterialItem,
  setPurchaseBillModalMode,
  setBuyerComment,
  setTransportChargesInBill,
  setHandlingChargesInBill,
  setAdditionalChargesInBill,
  setFiles,
  setInvoiceFile,
  setSubtotal,
  setGstTotal,
  setTotalOtherCharges,
  setGstOnOtherCharges,
  setGrandTotal,
  setIsGstIncludedInPrice,
  setPurchaseBillUploaded,
  resetPurchaseBill,
} = purchaseBillsSlice.actions

export default purchaseBillsSlice.reducer
