import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  id: null,
  companyCode: null,
  planType: null,
  endDate: null,
  blockedServices: [],
  trialExpiringSoon: false,
  daysLeft: 0,
  isExpired: false
}

const subscriptionSlice = createSlice({
  name: 'subscription',
  initialState,
  reducers: {
    setSubscription: (state, action) => {
      return { ...state, ...action.payload }
    },
    resetSubscription: () => initialState
  }
})

export const { setSubscription, resetSubscription } = subscriptionSlice.actions
export default subscriptionSlice.reducer
