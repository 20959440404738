import { createSlice, current } from '@reduxjs/toolkit'

const initialState = {
  materialsList: [],
  materialsOptions: []
}

export const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    setMaterialsOptions: (state, action) => {
      state.materialsOptions = action.payload
    },
    setSelectedMaterials: (state, action) => {
      state.materialsList = [...state.materialsList, action.payload]
    },
    resetMaterialsState: (state) => {
      return initialState
    }
  }
})

export const {
  setMaterialsOptions,
  setSelectedMaterials,
  resetMaterialsState
} = materialsSlice.actions
export default materialsSlice.reducer
