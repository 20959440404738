// ** Reducers Imports
import calendar from '@src/views/apps/calendar/store'
import chat from '@src/views/apps/chat/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import email from '@src/views/apps/email/store'
import invoice from '@src/views/apps/invoice/store'
import permissions from '@src/views/apps/roles-permissions/store'
import todo from '@src/views/apps/todo/store'
import users from '@src/views/apps/user/store'
import tableData from '@src/views/services/suppliers/store/SupplierManagement'
import branches from '@src/views/customerAdmin/roleAssignment/location/store/locationReducers'
import userReducers from '@src/views/customerAdmin/roleAssignment/user/store/userReducers'
import priceStructure from '@src/views/customerAdmin/priceStructure/store/priceStructureReducers'
import termsandCondition from '@src/views/customerAdmin/T&C/store/termsandConditionReducers'
import purchaseOrder from '@src/views/services/purchaseOrders/store/purchaseOrder.store'
import dataTables from '@src/views/tables/data-tables/store'
import auth from './authentication'
import layout from './layout'
import navbar from './navbar'
import Auction from '../views/services/auctions/store/AuctionReducers'
import userData from './userData'
import rfqReducer from '@src/views/services/rfq/store/Rfq-Reducers'
import rfqManagerReducer from '@src/views/services/rfq/store/Rfq-manager-reducers'
import mpRfqReducers from '@src/views/services/mp-rfqs/store/mp-rfq-reducers'
import chatReducers from '@src/views/services/chat/store/chat-reducers'
import cartReducers from '@src/views/publicServices/products-listing/store/product-cart-reducers'
import GRN from '@src/views/services/goods-received-notes/store/grn.store'
import materials from '@src/views/services/materials/store/materials.reducers.js'
import subscription from '@src/views/subscription/store/subscription.reducres'
import purchaseBills from '@src/views/services/purchaseBills/store/purchaseBills.store'

const rootReducer = {
  auth,
  todo,
  chat,
  email,
  users,
  navbar,
  layout,
  invoice,
  calendar,
  ecommerce,
  dataTables,
  permissions,
  tableData,
  branches,
  userReducers,
  priceStructure,
  termsandCondition,
  Auction,
  userData,
  rfqReducer,
  purchaseOrder,
  GRN,
  rfqManagerReducer,
  mpRfqReducers,
  chatReducers,
  cartReducers,
  materials,
  subscription,
  purchaseBills
}

export default rootReducer
